// components/GameBoard.tsx
import { useState, useEffect, useRef } from "react";
import { GAMES_TYPES, GAME_STATE_TYPES } from "types/gameTypes";
import { ToastContainer, toast } from "react-toastify";
import QRCode from "react-qr-code";
import TriviaQuestion from "components/games/Trivia/board/TriviaQuestion";
import useGameManager from "hooks/useGameManager"; // ייבוא ללא {}
import playerManager from "services/PlayersManager";
import "components/ProgressBar/progressBar.scss";
import TicTacToeBoard from "components/games/TicTacToe/board/ticTacToe_board";

const GameBoard = () => {
  let gameType: GAMES_TYPES = null;
  let minimumPlayers: number = 0;
  let maximumPlayers: number = 0;

  const queryParameters = new URLSearchParams(window.location.search);
  const _gametype = queryParameters.get("gt");
  if (_gametype === "trivia") {
    gameType = GAMES_TYPES.TRIVIA;
    minimumPlayers = 1;
    maximumPlayers = 8;
  } else if (_gametype === "tictactoe") {
    gameType = GAMES_TYPES.TICTACTOE;
    minimumPlayers = 2;
    maximumPlayers = 2;
  }

  const [boardId, setBoardId] = useState<string | null>(null);
  const gameMessagesRef = useRef<{ gameMessages: (message: any) => void }>(null);

  const sendData2GameController = (data: any) => {
    if(gameMessagesRef?.current?.gameMessages) gameMessagesRef?.current?.gameMessages(data);
  };

  const { gameState, playerCounter, sendGameMsg2Plyers } = useGameManager(
    boardId || "",
    gameType,
    maximumPlayers,
    minimumPlayers,
    sendData2GameController
  );

  useEffect(() => {
    fetch("https://antibug.co.il/api/boards/create/")
      .then((response) => response.json())
      .then((data) => setBoardId(data.boardID))
      .catch((error) => {
        console.error("Error fetching boardID:", error);
        toast.error("Error fetching boardID");
      });
  }, []);

  const renderGameBoard = () => {
    if (gameType === GAMES_TYPES.TRIVIA) {
      return <TriviaQuestion ref={gameMessagesRef} questionsCount={15} sendTriviaMsg={sendGameMsg2Plyers} timerInteval={20} />;
    } else if (gameType === GAMES_TYPES.TICTACTOE) {
      return <TicTacToeBoard ref={gameMessagesRef} timerInteval={10} sendBoardMsg={sendGameMsg2Plyers} />;
    }
  };

  const formatBoardId = (value) => {
    // הסרת כל תו שאינו מספר
    const numericValue = value.replace(/\D/g, "");

    // הגדרת פורמט בהתאם לאורך המספר
    if (numericValue.length > 6) {
      return `${numericValue.slice(0, 3)}-${numericValue.slice(3, 6)}-${numericValue.slice(6, 8)}`;
    } else if (numericValue.length > 3) {
      return `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`;
    }
    return numericValue; // אם פחות מ-3 תווים, מחזיר כמות רגילה
  };

  return (
    <div>
      <ToastContainer position="top-right" autoClose={4000} theme="light" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "100vh",
        }}>
        {gameState === GAME_STATE_TYPES.STARTING
          ? renderGameBoard()
          : gameState === GAME_STATE_TYPES.WAITING && (
              <div>
                <span style={{ fontSize: "11vw", fontWeight: "bold" }}>Game Board</span>
                {boardId && (
                  <div>
                    <div style={{ fontSize: "7vw", fontWeight: "bold" }}>ID : {formatBoardId(boardId.toString())}</div>
                    <div>
                      <h4>
                        Join the game by insert the ID on
                        <br />
                        www.antibug.co.il/player <br />
                        or scanning the QR code
                      </h4>
                    </div>
                    <div>
                      <br />
                      <QRCode size={256} value={`antibug.co.il/player?boardID=${boardId}`} />
                    </div>
                  </div>
                )}
                <br />
                <h4>
                  Players logged in: {playerManager.count()} / {maximumPlayers}
                </h4>
              </div>
            )}
      </div>
    </div>
  );
};

export default GameBoard;
